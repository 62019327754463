import { useAuthentication } from '@/lib/auth';
import { MAX_PERCENT_PURCHASE_PRICE } from '@components/DownPayment';
import { Modal } from '@components/common';
import { PhoneInput } from '@components/index';
import { API_STATUS } from '@models/enums/apiStatus.enum';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { ROUTES } from '@models/enums/routes.enum';
import { PATTERNS } from '@models/patterns.constants';
import { DealPurpose, LeadSource, PropertyOccupationType } from '@pinecorpca/evergreen';
import { Button, Checkbox } from '@pinecorpca/spruce';
import Input from '@spruce/Inputs/Input/Input';
import { shortAppApi } from 'api/evergreen';
import { LONG_APP_ROUTES } from 'containers/long-app/models/routes.model';
import { useAnalyticsContext, usePartnerContext, useShortAppContext } from 'contexts';
import { useNavigationRouter } from 'hooks/useNavigationRouter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import Styled from './Register.styles';
import Policy from './components/Policy';
import { useAmplitude } from 'contexts/amplitude';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  newsletterOptIn: boolean;
};

const Register = (): JSX.Element => {
  const [apiState, setApiState] = useState(API_STATUS.INIT);
  const [showDialog, setDialog] = useState(false);
  const { safeReplace } = useNavigationRouter();
  const { body } = useShortAppContext();
  const { analytics } = useAnalyticsContext();
  const { t } = useTranslation(NAMESPACE.DEFAULT);
  const { handleSubmit, control } = useForm<FormValues>();
  const { doLogin } = useAuthentication();
  const { partner } = usePartnerContext();
  const { enableReferral } = useFlags();
  const { trackEvent } = useAmplitude();

  const isWSReferral = useMemo(
    () =>
      !!enableReferral &&
      partner === LeadSource.Wealthsimple &&
      (body?.propertyOccupationType === PropertyOccupationType.Rental ||
        (body?.purchasePrice ?? 0) >= MAX_PERCENT_PURCHASE_PRICE ||
        body?.dealPurpose === DealPurpose.Refinance),
    [body?.dealPurpose, body?.propertyOccupationType, body?.purchasePrice, enableReferral, partner]
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      setApiState(API_STATUS.LOADING);
      try {
        const payload = { ...body, ...values };
        const { data } = await shortAppApi.apiShortAppPost({ shortAppCompleteDto: payload });

        setApiState(API_STATUS.LOADED);
        analytics?.identify(data.createdUser?.id, {
          email: values.email,
          name: `${values.firstName} ${values.lastName}`,
        });
        window?.dataLayer?.push({
          user_id: data.createdUser?.id,
          event: 'userIdSet',
        });
        trackEvent('Short App Completed', {
          section: 'register',
          step: 'short-app-complete',
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
        });

        if (data.sentToWealthsimple) {
          safeReplace(`/${ROUTES.PARTNER_REFERRAL}`);
        } else {
          safeReplace(`/${ROUTES.APPLICATIONS}/${data.mortgageId}/${LONG_APP_ROUTES.RATES}`);
        }
      } catch (e: any) {
        if (e.response?.status === 409) setDialog(true);
        setApiState(API_STATUS.ERROR);
      }
    },
    [analytics, body, safeReplace, trackEvent]
  );

  useEffect(() => {
    if (isEmpty(body)) safeReplace(ROUTES.ROOT);
  }, [body, safeReplace]);

  if (isEmpty(body)) return <></>;

  return (
    <>
      <Styled.Container>
        <Styled.Wrapper>
          <Styled.Title data-testid="register-title">{t(isWSReferral ? 'REGISTER_INVESTMENT_PROPERTY' : 'REGISTER')}</Styled.Title>
          <Styled.Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="firstName"
              rules={{
                required: {
                  value: true,
                  message: t('FORM_FIRST_NAME_REQUIRED'),
                },
                maxLength: {
                  value: 75,
                  message: t('FORM_ERROR_EXCEEDED'),
                },
              }}
              render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                <Input
                  name={name}
                  onChange={onChange}
                  value={value ?? ''}
                  id="register-first-name"
                  data-testid="register-first-name"
                  label={t('FORM_FIRST_NAME')}
                  invalid={!!error}
                  placeholder={t('FORM_FIRST_NAME_PLACEHOLDER')}
                  errorMessage={error?.message}
                  ref={ref}
                />
              )}
            />

            <Controller
              control={control}
              name="lastName"
              rules={{
                required: {
                  value: true,
                  message: t('FORM_LAST_NAME_REQUIRED'),
                },
                maxLength: {
                  value: 75,
                  message: t('FORM_ERROR_EXCEEDED'),
                },
              }}
              render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                <Input
                  name={name}
                  onChange={onChange}
                  value={value ?? ''}
                  id="register-last-name"
                  data-testid="register-last-name"
                  label={t('FORM_LAST_NAME')}
                  placeholder={t('FORM_LAST_NAME_PLACEHOLDER')}
                  invalid={!!error}
                  errorMessage={error?.message}
                  ref={ref}
                />
              )}
            />

            <Controller
              control={control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: t('FORM_EMAIL_REQUIRED'),
                },
                pattern: {
                  value: PATTERNS.EMAIL,
                  message: t('FORM_EMAIL_INVALID'),
                },
              }}
              render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                <Input
                  name={name}
                  onChange={onChange}
                  value={value ?? ''}
                  id="register-email"
                  data-testid="register-email"
                  label={t('FORM_EMAIL')}
                  placeholder={t(partner === LeadSource.Wealthsimple ? 'FORM_EMAIL_WS_PLACEHOLDER' : 'FORM_EMAIL_PLACE_HOLDER')}
                  invalid={!!error}
                  errorMessage={error?.message}
                  type="email"
                  hint={partner === LeadSource.Wealthsimple ? t('FORM_EMAIL_WS_TIP') : undefined}
                  ref={ref}
                />
              )}
            />
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                required: {
                  value: true,
                  message: t('FORM_PHONE_REQUIRED', { ns: NAMESPACE.DEFAULT }),
                },
                validate: (value: string) => isPossiblePhoneNumber(value) || 'FORM_PHONE_INVALID',
              }}
              render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                <PhoneInput
                  label={t('FORM_PHONE')}
                  name={name}
                  id="register-phone"
                  onChange={onChange}
                  control={control as unknown as Control<FieldValues, any>}
                  value={value}
                  data-testid="register-phone"
                  invalid={!!error}
                  placeholder={t('FORM_PHONE_PLACEHOLDER')}
                  errorMessage={t(error?.message ?? '')}
                  ref={ref}
                />
              )}
            />

            <Controller
              control={control}
              name="newsletterOptIn"
              render={({ field: { onChange, name, ref } }) => (
                <Styled.CheckBoxWrapper>
                  <Checkbox
                    id="newsletter"
                    label={t('FORM_NEWSLETTER')}
                    onChange={onChange}
                    name={name}
                    hoverBackgroundDisabled
                    fontStyle={{
                      fontSize: '12px',
                    }}
                    ref={ref}
                  />
                </Styled.CheckBoxWrapper>
              )}
            />
            <Styled.FooterWrapper>
              <Policy />
              <Styled.SubmitButton data-testid="submit-register-cta" loading={apiState === API_STATUS.LOADING}>
                {t(isWSReferral ? 'CTA_CREATE_APPLICATION' : 'CTA_SEE_RATES')}
              </Styled.SubmitButton>
            </Styled.FooterWrapper>
          </Styled.Form>
        </Styled.Wrapper>
      </Styled.Container>
      <Modal onClose={() => setDialog(false)} open={showDialog}>
        <Styled.Modal data-testid="existing-error-modal">
          <h3 className="modal-heading">{t('REGISTER_DUPLICATE_HEADER')}</h3>
          <p className="modal-content">{t('REGISTER_DUPLICATE_CONTENT')}</p>
          <div className="button-wrapper">
            <Button onClick={() => doLogin()}>{t('REGISTER_DUPLICATE_CONTINUE')}</Button>
          </div>
        </Styled.Modal>
      </Modal>
    </>
  );
};

export default Register;
